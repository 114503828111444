import topic from '../topic';
import { getNoCatch, post, postNoCatch } from '@api/request';
export const rqutAPI = {
  getRquts: topic.rqutTopic + '/get_rquts',
  getRqutById: topic.rqutTopic + '/get_rqut_by_id',
  addRqut: topic.rqutTopic + '/add_rqut',
  editRqut: topic.rqutTopic + '/edit_rqut',
  deleteRqutByIds: topic.rqutTopic + '/delete_rqut_by_ids',
  getRqutTheme: topic.rqutTopic + '/get_rqut_theme',
  getAllRqutsV1: topic.rqutTopic + '/get_all_rquts_v1',
  getInquirySheetList: params => getNoCatch('/api/inquirySheet/getInquirySheetList', params),
  deleteInquirySheeInquiryId: params => postNoCatch('/api/inquirySheet/deleteInquirySheeInquiryId', params),
  getInquirySheetByInquiryId: params => getNoCatch('/api/inquirySheet/getInquirySheetByInquiryId', params),
  getQutcSmpls: params => getNoCatch('/api/inquirySheet/get_qutc_smpls', params),
  addInquiryShee: params => postNoCatch('/api/inquirySheet/addInquiryShee', params),
  editInquiryShee: params => postNoCatch('/api/inquirySheet/editInquiryShee', params),
  getQutbSmpls: params => getNoCatch('/api/inquirySheet/get_qutb_smpls', params),
  getRqtaList: params => getNoCatch('/api/open/dyj/api/getRqtaList', params),
  getFequSmpls: params => getNoCatch('/api/open/dyj/api/getFequSmpls', params)
};
