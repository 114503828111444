<template>
  <div>
    <div v-if="prodForm.prod_attr === '1'" class="vg_mb_16">
      <el-button size="small" type="primary" @click="$emit('addProdPartList')">新增</el-button>
      <el-button plain size="small" type="primary" @click="clickImport">绑定部件</el-button>
      <el-button plain size="small" type="danger" @click="clickDel">删除</el-button>
    </div>
    <DynamicUTable
      ref="partDynamicUTable"
      :columns="partTableProperties"
      :is-show="isShow"
      :need-pagination="false"
      :needToVerifyCharacterLength="true"
      :table-data="prodForm.prod_part_list"
      @selection-change="handleSelectionChange"
    >
      <template v-slot:prod_poststfix="scope">
        <el-form-item :prop="'prod_part_list.' + scope.$index + '.prod_poststfix'" label-width="0">
          <el-input v-model="scope.row.prod_poststfix" :disabled="scope.row.disabled" maxlength="20" placeholder="暂无货号后缀" show-word-limit></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_cust_no="scope">
        <el-form-item :prop="'prod_part_list.' + scope.$index + '.prod_cust_no'" :rules="[{ required: true }]" label-width="0">
          <template slot="label"><span style="display: none">客户货号</span></template>
          <el-input v-model="scope.row.prod_cust_no" disabled maxlength="12" placeholder="暂无客户货号" show-word-limit></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_price="scope">
        <el-form-item :prop="'prod_part_list.' + scope.$index + '.prod_price'" label-width="0">
          <el-input
            v-model="scope.row.prod_price"
            maxlength="9"
            placeholder="暂无采购价"
            show-word-limit
            @input="scope.row.prod_price = keep4Decimal(scope.row.prod_price)"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_unit="scope">
        <el-form-item :prop="'prod_part_list.' + scope.$index + '.prod_unit'" :rules="[{ required: true }]" label-width="0">
          <el-select v-model="scope.row.prod_unit" clearable filterable placeholder="请选择" size="mini">
            <el-option v-for="(item, index) in unitList" :key="index + 'prodMainSup'" :label="item.label" :value="item.label" />
          </el-select>
        </el-form-item>
      </template>
      <template v-slot:prod_use="scope">
        <el-form-item :prop="'prod_part_list.' + scope.$index + '.prod_use'" :rules="[{ required: true }]" label-width="0">
          <template slot="label"><span style="display: none">用量</span></template>
          <el-input
            v-model="scope.row.prod_use"
            :disabled="scope.row.disabled"
            maxlength="8"
            placeholder="暂无用量"
            show-word-limit
            @input="val => (scope.row.prod_use = keep4Decimal(val))"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:supp_abbr="scope">
        <el-form-item :prop="'prod_part_list.' + scope.$index + '.supp_abbr'" label-width="0">
          <el-select v-model="scope.row.supp_id" :disabled="scope.row.disabled" filterable placeholder="请选择供应商简称" size="mini">
            <el-option v-for="item in suppList" :key="item.supp_id" :label="item.supp_abbr + '(' + item.supp_no + ')'" :value="item.supp_id"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <template v-slot:prod_mainsup="scope">
        <el-form-item :prop="'prod_part_list.' + scope.$index + '.prod_mainsup'" :rules="[{ required: true }]" label-width="0">
          <el-select v-model="scope.row.prod_mainsup" filterable placeholder="是否主商品" size="mini" @change="mainProd(scope.$index)">
            <el-option
              v-for="(item, index) in [
                { label: '是', value: 1 },
                { label: '否', value: 0 }
              ]"
              :key="index + 'prodMainSup'"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </template>
      <template v-slot:operation="scope">
        <div v-if="scope.row.disabled">
          <el-link class="vg_pointer" type="primary" @click="clickLook(scope.row)">查看</el-link>
          <el-link :disabled="isShow" class="vg_pointer vg_ml_16" type="primary" @click="refresh(scope.row, scope.$index)"> 刷新</el-link>
        </div>
        <el-link v-else-if="!scope.row.state" :disabled="isShow" class="vg_pointer" type="primary" @click="showSupplementInfoDialog(scope.$index)"
          >补充信息
        </el-link>
      </template>
    </DynamicUTable>
    <el-dialog v-if="dialogVisible2" :visible.sync="dialogVisible2" title="补充信息" width="70%">
      <el-form
        ref="supplementInfo"
        :model="prodForm.prod_part_list[supplementInfoIndex].supplementInfo"
        :rules="{ prod_hscode: [{ required: true, trigger: 'blur', message: ' ' }] }"
        label-width="120px"
        size="mini"
      >
        <el-form-item label="中文描述" prop="prod_desc">
          <el-input
            v-model="prodForm.prod_part_list[supplementInfoIndex].supplementInfo.description"
            :rows="2"
            class="vg_mb_8"
            placeholder="请填写描述"
            show-word-limit
            type="textarea"
          >
          </el-input>
        </el-form-item>
        <el-row>
          <el-col :span="24" class="vg_mb_5">
            <h3 class="vg_mb_5" style="color: #606266">报关信息</h3>
          </el-col>
          <prodCustom :isShow="isShow" :prodForm="prodForm.prod_part_list[supplementInfoIndex].supplementInfo" :required="true"></prodCustom>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="submitSupplementInfo()">提 交</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible" title="部件" top="0" width="70%" @opened="getProdParts(prodForm.prod_no)">
      <el-row class="vg_mt_16">
        <el-col :md="16">
          <el-button size="mini" type="danger" @click="clickCancel">取消选择</el-button>
          <el-button class="vg_mr_8" size="mini" type="primary" @click="confirmIn">确认选择</el-button>
          <el-button class="vd_export" size="small" type="info" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16">
        <el-col :md="24">
          <search-table
            ref="multiTable"
            v-loading="loading"
            :columns="tableProperties"
            :data="tableData"
            :need-fixed-height="true"
            :tableRowKey="'prod_id'"
            :total-page="total"
            @getTableData="getProdParts"
            @selection-change="handleSelectionChangeImport"
          />
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { get, getNoCatch } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import { suppAPI } from '@/api/modules/supp';
import { prodAPI } from '@/api/modules/prod';
import pubPagination from '@/components/common/pubPagination';
import textEnlargement from '@/views/component/textEnlargement.vue';
import ProdCustom from '@/views/MessageManagement/ProdManage/TabChild/Componet/ProdCustom.vue';
import ProdPartsInfo from '@/views/MessageManagement/ProdManage/TabChild/Componet/ProdPartsInfo.vue';
import { keep4Decimal, keepLetter2AndNumber5, keepNumber } from '@assets/js/regExUtil';
import { deleteTableRow } from '@assets/js/arrayUtils';
import { cloneDeep } from 'lodash';
import { getUnit } from '@api/public';
import DynamicForm from '@/components/DynamicFormV2.vue';
import { diningMat, food, other, woodwork } from '@/views/MessageManagement/ProdManage/TabChild/ProdDetails';
import SearchTable from '@/components/table/SearchTableV2.vue';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { partTableProperties } from '@/views/MessageManagement/ProdManage/prod';
import { BigNumber } from 'bignumber.js';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'ProdPartsList',
  computed: {
    BigNumber() {
      return BigNumber;
    }
  },
  components: {
    DynamicUTable,
    SearchTable,
    DynamicForm,
    ProdPartsInfo,
    ProdCustom,
    pubPagination,
    textEnlargement
  },
  props: {
    prodForm: {
      type: Object,
      required: true
    },
    isShow: {
      type: Boolean,
      required: false
    },
    ProdCategory: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      mainForm: {
        split: 8,
        totalWidth: 24,
        formProperties: cloneDeep(other)
      },
      partTableProperties: cloneDeep(partTableProperties),
      tableProperties: [
        this.$store.state.selection,
        { label: '我司货号', prop: 'prod_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '货号后缀', prop: 'prod_poststfix', itemType: 'input', input: false, sortable: false, widthAuto: true },
        { label: '部件名称', prop: 'prod_partno', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '中文名称', prop: 'prod_name', itemType: 'input', input: false, sortable: false, widthAuto: true },
        { label: '客户货号', prop: 'prod_cust_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '采购价', prop: 'prod_price', itemType: 'input', input: false, sortable: false, widthAuto: true },
        { label: '用量', prop: 'prod_use', itemType: 'input', input: false, sortable: false, widthAuto: true },
        { label: '供应商', prop: 'supp_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
        {
          label: '是否主商品',
          prop: 'prod_mainsup',
          itemType: 'input',
          input: false,
          sortable: false,
          widthAuto: true,
          formatter: val => this.getProdMainsup(val)
        }
      ],
      prod_part_list_map: new Map(),
      supplementInfoIndex: null,
      dialogVisible2: false,
      dialogVisible: false,
      searchForm: {
        page_no: 1,
        prod_no: null,
        prod_partno: null,
        prod_cust_no: null,
        supp_id: null
      },
      tableData: [],
      total: 0,
      loading: false,
      selectTab: [],
      suppList: [],
      smplFlag: 0,
      selectionsList: [],
      smplType: [
        { id: 1, label: '新款' },
        { id: 2, label: '老款' },
        { id: 3, label: '修改款' }
      ],
      selectFlag: false,
      beloType: [],
      prodMainsup: [
        { value: 1, label: '成品' },
        { value: 0, label: '部件' }
      ],
      temp1: '是',
      temp2: '否',
      textEnlargementNum: 0,
      textEnlargementFlag: 0,
      textEnlargementString: {
        str: ''
      },
      unitList: []
    };
  },
  mounted() {
    this.initData();
    let prod_no = this.partTableProperties.find(x => x.label === '我司货号');

    if (prod_no && this.$route.path === '/prod_add') {
      prod_no.subItem.disabled = false;
      prod_no.subItem.input = (val, row) => (row.prod_no = keepLetter2AndNumber5(val));
    } else {
      prod_no.subItem.disabled = true;
    }
  },
  methods: {
    keep4Decimal,
    keepNumber,
    async initData() {
      this.getContry();
      this.getSupp();
      this.unitList = await getUnit('select', 'param1', 'param2');
    },
    //绑定部件弹窗获取数据
    getProdParts(val) {
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      //第一次进来默认用主表我司货号搜索
      if (val === this.prodForm.prod_no) {
        searchForm.prod_no = this.prodForm.prod_no;
        this.$refs.multiTable.searchForm.prod_no = searchForm.prod_no;
      }
      get(prodAPI.getProdParts, searchForm)
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.total = res.data.data.total;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(err => console.error(err));
    },
    getSupp() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(err => console.error(err));
    },
    productTypeChange(val) {
      switch (val) {
        case '餐垫':
          this.mainForm.formProperties = cloneDeep(diningMat);
          break;
        case '木制品':
          this.mainForm.formProperties = cloneDeep(woodwork);
          break;
        case '食品':
          this.mainForm.formProperties = cloneDeep(food);
          break;
      }
    },
    category(val, supplementInfoIndex) {
      /*switch (val) {
        case '商检类':
          this.mainForm.formProperties = cloneDeep(inspFrom);
          if (this.prodForm.prodDetailed === null) {
            this.prodForm.prod_part_list[supplementInfoIndex].prodDetailed = {};
            this.prodForm.prod_part_list[supplementInfoIndex].prod_category = '商检类';
            this.prodForm.prod_part_list[supplementInfoIndex].prodDetailed.product_type = '餐垫';
          }
          this.productTypeChange('餐垫');
          break;
        case '宠物包':
          this.mainForm.formProperties = cloneDeep(petBag);
          break;
        case '毛绒玩具':
          this.mainForm.formProperties = cloneDeep(plushToys);
          break;
        case '织带':
          this.mainForm.formProperties = cloneDeep(webbing);
          break;
        case '汗衫布绳':
          this.mainForm.formProperties = cloneDeep(sweatshirt);
          break;
        case '宠物衣服':
          this.mainForm.formProperties = cloneDeep(petClothing);
          break;
        case 'TPR':
          this.mainForm.formProperties = cloneDeep(tpr);
          break;
        case '宠物配饰（帽子、领结、围巾等）':
          this.mainForm.formProperties = cloneDeep(petAccessories);
          break;
        case '尼龙':
          this.mainForm.formProperties = cloneDeep(nylon);
          break;
        case '橡胶':
          this.mainForm.formProperties = cloneDeep(rubber);
          break;
        case '宠物碗':
          this.mainForm.formProperties = cloneDeep(petBowl);
          break;
        case '链条、项圈、胸背':
          this.mainForm.formProperties = cloneDeep(chain);
          break;
        case '宠物窝':
          this.mainForm.formProperties = cloneDeep(petNest);
          break;
      }*/
    },
    // 获取打样部门
    getContry() {
      get(optnAPI.getOptnByPermId, { perm_id: 10009 })
        .then(res => {
          if (res.data.code === 0) {
            let beloType = res.data.data.form.optn_cntt_list;
            for (let item of beloType) {
              item.param2 = Number(item.param2);
            }
            this.beloType = beloType;
          }
        })
        .catch(err => console.error(err));
    },
    // 刷新数据
    refresh(val, index) {
      getNoCatch(prodAPI.getProdById, {
        prod_id: val.part_id,
        prod_attr: val.prod_attr,
        product_part_id: val.product_part_id
      })
        .then(({ data }) => {
          data.form.prod_price = this.helper.haveFour(data.form.prod_price);
          this.$set(this.prodForm.prod_part_list, index, data.form);
        })
        .catch(err => console.error(err));
    },
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
    },
    changePageSearch(val) {
      this.searchForm.page_no = val;
    },
    getRowKey(row) {
      return row.prod_id;
    },
    getProdMainsup(row) {
      if (row === 1) {
        return '是';
      } else {
        return '否';
      }
    },
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      this.getProdParts();
    },
    getQutbNow() {
      this.searchForm.page_no = 1;
      this.$refs.pubPagination.currentPage = 1;
      this.getProdParts();
    },
    handleSelectionChangeImport(select) {
      this.selectTab = select;
    },
    clickImport() {
      this.dialogVisible = true;
      this.searchForm.prod_no = this.prodForm.prod_no;
      // this.getProdParts();
    },
    // 框显示效果
    textEnlargementShow(val, val1) {
      this.textEnlargementFlag = val;
      this.textEnlargementNum = val1;
      if (val === 0) {
        this.textEnlargementString.str = this.prodForm.prod_part_list[val1].prod_partno;
      }
    },
    // 更改值
    textEnlargementChange(val) {
      if (this.textEnlargementFlag === 0) {
        this.prodForm.prod_part_list[this.textEnlargementNum].prod_partno = val;
      }
    },
    //取消选择
    clickCancel() {
      this.$refs.multiTable.clearSelection();
      this.dialogVisible = false;
    },
    confirmIn() {
      let arr = [];
      this.selectTab.forEach(item => {
        let flag = this.prodForm.prod_part_list.some(item2 => {
          return item.part_id === item2.part_id;
        });
        if (!flag) {
          arr.push(item);
        }
      });

      arr.forEach(item => {
        item.prod_price = this.helper.haveFour(item.prod_price);
        item['disabled'] = true;
        this.prodForm.prod_part_list.push({ ...item });
      });
      this.$refs.multiTable.clearSelection();
      this.dialogVisible = false;
    },
    clickLook(row) {
      let props = {};
      if (this.$route.path.indexOf('add') !== -1) props = this.$route.query;
      else props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      this.jump('/prod_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: props.perm_id,
            form_id: row.part_id,
            prod_attr: row.prod_attr
          })
        )
      });
    },
    clickDel() {
      if (this.selectionsList.length === 0) {
        return this.$message.info('至少选择一条信息');
      }
      this.$confirm('确定移除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let { tableData, map } = deleteTableRow('product_part_id', this.prodForm.prod_part_list, this.prod_part_list_map, this.selectionsList);
          this.prodForm.prod_part_list = tableData;
          this.prod_part_list_map = map;
          // this.selectionsList.forEach((item, index) => {
          //   this.prodForm.prod_part_list.forEach((item2, index2) => {
          //     if (item.prod_id && item2.prod_id) {
          //       if (item.prod_id === item2.prod_id) {
          //         this.prodForm.prod_part_list.splice(index2, 1);
          //       }
          //     }
          //   });
          // });
        })
        .catch(err => console.error(err));
    },
    async showSupplementInfoDialog(index) {
      //设置部件的必填项和字段显示
      // this.mainForm.formProperties.find(({ prop }) => prop === 'cn_description').rules.required = true;
      // this.mainForm.formProperties.find(({ prop }) => prop === 'description').show = false;
      // this.mainForm.formProperties.find(({ prop }) => prop === 'cn_description').show = true;
      if (this.prodForm.prod_part_list[index].prod_category === null) {
        this.prodForm.prod_part_list[index].prod_category = this.prodForm.prod_category;
      }
      await (this.supplementInfoIndex = index);
      // if (typeof this.prodForm.prod_part_list[this.supplementInfoIndex].supplementInfo === 'undefined') {
      //   this.prodForm.prod_part_list[this.supplementInfoIndex].supplementInfo = { prod_hscode: '', prod_spec: '' };
      // }

      // await this.category('其他', index);
      // await this.productTypeChange(this.prodForm.prod_part_list[index].prodDetailed.product_type);
      //await this.mainForm.formProperties.forEach(item => (item.model = this.prodForm.prod_part_list[index].prodDetailed[item.prop]));
      await (this.dialogVisible2 = true);
    },
    submitSupplementInfo() {
      this.$refs.supplementInfo.validate(valid => {
        if (valid) {
          let obj = cloneDeep(this.prodForm.prod_part_list[this.supplementInfoIndex]);
          obj.prod_desc = obj.supplementInfo.prod_desc;
          obj.prod_ehsname = obj.supplementInfo.prod_ehsname;
          obj.prod_espec = obj.supplementInfo.prod_espec;
          obj.prod_hscode = obj.supplementInfo.prod_hscode;
          obj.prod_hsinrate = obj.supplementInfo.prod_hsinrate;
          obj.prod_hsname = obj.supplementInfo.prod_hsname;
          obj.prod_hsoutrate = obj.supplementInfo.prod_hsoutrate;
          obj.prod_spec = obj.supplementInfo.prod_spec;
          obj.description = obj.supplementInfo.description;
          obj.submit = true;
          this.$set(this.prodForm.prod_part_list, this.supplementInfoIndex, obj);
          this.dialogVisible2 = false;
        } else {
          return false;
        }
      });
    },
    mainProd(index) {
      if (this.prodForm.prod_part_list[index].prod_mainsup === 1) {
        this.prodForm.prod_part_list.forEach((item, index2) => {
          if (index !== index2) {
            item.prod_mainsup = 0;
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.vd_total {
  font-size: 24px;
  text-align: right;
  width: 100%;
  padding-right: 30px;
  box-sizing: border-box;
}

.vd_color {
  color: #c70e0e;
}

::v-deep .el-form-item.is-required .el-form-item__label:before {
  content: '';
  display: none;
}
</style>
