import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const prodAPI = {
  getProds: topic.prodTopic + '/get_prods',
  addProd: topic.prodTopic + '/add_prod',
  getProdById: topic.prodTopic + '/get_prod_by_id',
  editProd: topic.prodTopic + '/edit_prod',
  deleteProdByIds: topic.prodTopic + '/delete_prod_by_ids',
  getProdParts: topic.prodTopic + '/get_prod_parts',
  getAllProdsV1: topic.prodTopic + '/get_all_prods_v1',
  getProdPartsByProdId: topic.prodTopic + '/get_prod_parts_by_prod_id',
  getProdMtrbByProdId: topic.prodTopic + '/get_prod_mtrb_by_prod_id',
  copyProd: params => postNoCatch(`${topic.prodTopic}/copy_prod`, params),
  getProductList: params => getNoCatch(`${topic.prodTopic}/getProductList`, params),
  addProductByMessage: params => postNoCatch(`${topic.prodTopic}/addProductByMessage`, params),
  getImageUrlByProdNo: params => getNoCatch(`${topic.prodTopic}/getSmpl`, params),
  getProdList: params => getNoCatch(`${topic.prodTopic}/getProdList`, params),
  getProdOne: params => getNoCatch(`${topic.prodTopic}/getProdById`, params),
  getProdToPodr: params => getNoCatch(`${topic.prodTopic}/get_prod_to_podr`, params),
  edit_part_abolish: topic.prodTopic + '/edit_part_abolish'
};
