//餐垫
export const diningMat = [
  {
    label: '英文描述',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'price_composition',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品材质',
    prop: 'prod_material',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品颜色',
    prop: 'prod_color',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品尺寸',
    prop: 'prod_size',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品克重',
    prop: 'prod_weight',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '厚度',
    prop: 'thickness',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品描述',
    prop: 'prod_description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '熏蒸要求',
    prop: 'stifling_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '商检证书',
    prop: 'insp_certificate',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '包装要求',
    prop: 'pack_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '生产标准及其他',
    prop: 'standard_other',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  }
];
//木制品
export const woodwork = [
  {
    label: '英文描述',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '价格组成',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'price_composition',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品材质',
    prop: 'prod_material',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品颜色',
    prop: 'prod_color',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '各部位尺寸',
    prop: 'part_size',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品克重',
    prop: 'prod_weight',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '含水量',
    prop: 'water_content',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品描述',
    prop: 'prod_description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '熏蒸要求',
    prop: 'stifling_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '商检证书',
    prop: 'insp_certificate',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '包装要求',
    prop: 'pack_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '生产标准及其他',
    prop: 'standard_other',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  }
];
//食品
export const food = [
  {
    label: '英文描述',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '价格组成',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'price_composition',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '成份',
    prop: 'ingredients',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '营养成份',
    prop: 'nutrient_ingredients',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品克重',
    prop: 'prod_weight',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '质量要求',
    prop: 'quality_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '包装要求',
    prop: 'pack_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品描述',
    prop: 'prod_description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '测试要求及其他',
    prop: 'test_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  }
];

//商检类
export const inspFrom = [];
//宠物包
export const petBag = [
  {
    label: '英文描述',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '价格组成',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'price_composition',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品克重',
    prop: 'prod_weight',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品颜色',
    prop: 'prod_color',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品尺寸',
    prop: 'prod_size',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品材质',
    prop: 'prod_material',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品描述',
    prop: 'prod_description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '包装物UPC码',
    prop: 'upc_code',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '包装要求',
    prop: 'pack_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '生产标准及其他',
    prop: 'standard_other',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  }
];
//毛绒玩具
export const plushToys = [
  {
    label: '英文描述',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '价格组成',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'price_composition',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品尺寸',
    prop: 'prod_size',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品克重',
    prop: 'prod_weight',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '面料规格',
    prop: 'fabric_specifications',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '填充物及其克重',
    prop: 'filler_weight',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '布标织标材质尺寸材质内容及位置',
    prop: 'content_location',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '包装物UPC码',
    prop: 'upc_code',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '包装要求',
    prop: 'pack_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品外观描述',
    prop: 'prod_face_describe',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '注意事项及其他',
    prop: 'note',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  }
];
//织带
export const webbing = [
  {
    label: '英文描述',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '价格组成',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'price_composition',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品尺寸',
    prop: 'prod_size',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品克重',
    prop: 'prod_weight',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '织带材质及描述',
    prop: 'ribbon_material',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '测试要求',
    prop: 'test_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '包装要求',
    prop: 'pack_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '注意事项及其他',
    prop: 'note',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  }
];
//汗衫布绳
export const sweatshirt = [
  {
    label: '英文描述',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '价格组成',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'price_composition',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '材质及描述',
    prop: 'ribbon_material',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '附带产品描述',
    prop: 'prod_description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '造型描述',
    prop: 'mold_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '包装要求',
    prop: 'pack_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '测试要求',
    prop: 'test_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '注意事项及其他',
    prop: 'note',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  }
];
//宠物衣服
export const petClothing = [
  {
    label: '英文描述',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '价格组成',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'price_composition',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品尺寸',
    prop: 'prod_size',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品克重',
    prop: 'prod_weight',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '纱线名称规格成份',
    prop: 'yarn_name_specs',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '填充物及其克重',
    prop: 'filler_weight',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '布标织标材质尺寸材质内容及位置',
    prop: 'content_location',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '包装物UPC码',
    prop: 'upc_code',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '包装要求',
    prop: 'pack_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品外观描述',
    prop: 'prod_face_describe',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '注意事项及其他',
    prop: 'note',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  }
];
//TPR
export const tpr = [
  {
    label: '英文描述',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '价格组成',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'price_composition',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品尺寸',
    prop: 'prod_size',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品材质',
    prop: 'prod_material',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品颜色',
    prop: 'prod_color',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '详细描述',
    prop: 'details',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '包装要求',
    prop: 'pack_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '配件要求',
    prop: 'accessory_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  }
];
//宠物配饰
export const petAccessories = [
  {
    label: '英文描述',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '价格组成',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'price_composition',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品材质',
    prop: 'prod_material',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品颜色',
    prop: 'prod_color',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品尺寸',
    prop: 'prod_size',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品克重',
    prop: 'prod_weight',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品数量',
    prop: 'prod_quantity',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品描述',
    prop: 'prod_description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '包装物UPC码',
    prop: 'upc_code',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '包装要求',
    prop: 'pack_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '测试要求及其他',
    prop: 'test_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  }
];
//尼龙
export const nylon = [
  {
    label: '是否喷漆',
    prop: 'is_painting',
    itemType: 'radio',
    model: '',
    rules: { required: true, message: ' ' },
    radioOptions: [
      { label: '需要', text: '需要' },
      { label: '不需要', text: '不需要' }
    ],
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '英文描述',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '价格组成',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'price_composition',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品克重',
    prop: 'prod_weight',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品尺寸',
    prop: 'prod_size',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品颜色',
    prop: 'prod_color',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '硬度要求',
    prop: 'hardness_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '香精要求',
    prop: 'essence_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: 'Logo尺寸&位置',
    prop: 'logo_size_position',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '是否需要产前样',
    prop: 'prenatal_sample',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '包装要求',
    prop: 'pack_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品造型描述',
    prop: 'mold_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '橡胶颗粒/矿粉要求',
    prop: 'particle_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  }
];
//橡胶
export const rubber = [
  {
    label: '是否喷漆',
    prop: 'is_painting',
    itemType: 'radio',
    model: '',
    rules: { required: true, message: ' ' },
    radioOptions: [
      { label: '需要', text: '需要' },
      { label: '不需要', text: '不需要' }
    ],
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '英文描述',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '价格组成',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'price_composition',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品克重',
    prop: 'prod_weight',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品尺寸',
    prop: 'prod_size',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '硬度要求',
    prop: 'hardness_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '壁厚要求',
    prop: 'wall_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '香精要求',
    prop: 'essence_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '夜光粉要求',
    prop: 'ymb_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '有无叫叫',
    prop: 'is_call',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: 'Logo尺寸&位置',
    prop: 'logo_size_position',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '是否需要产前样',
    prop: 'prenatal_sample',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '包装要求',
    prop: 'pack_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品造型描述',
    prop: 'mold_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '橡胶颗粒/矿粉要求',
    prop: 'particle_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  }
];
//宠物碗
export const petBowl = [
  {
    label: '产品类型',
    prop: 'product_type',
    itemType: 'radio',
    model: '',
    rules: { required: true, message: ' ' },
    radioOptions: [
      { label: '硅胶碗', text: '硅胶碗' },
      { label: '塑料碗', text: '塑料碗' },
      { label: '密胺碗', text: '密胺碗' },
      { label: '陶瓷碗', text: '陶瓷碗' }
    ],
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '英文描述',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '价格组成',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'price_composition',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品材质',
    prop: 'prod_material',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品颜色',
    prop: 'prod_color',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品尺寸',
    prop: 'prod_size',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品克重',
    prop: 'prod_weight',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品数量',
    prop: 'prod_quantity',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '包装物UPC码',
    prop: 'upc_code',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '包装要求',
    prop: 'pack_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品描述',
    prop: 'prod_description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '测试要求及其他',
    prop: 'test_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  }
];
//链条、项圈、胸背
export const chain = [
  {
    label: '英文描述',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '价格组成',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'price_composition',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品材质',
    prop: 'prod_material',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品颜色',
    prop: 'prod_color',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '部位尺寸',
    prop: 'prod_size',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品克重',
    prop: 'prod_weight',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品描述',
    prop: 'prod_description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '包装物UPC码',
    prop: 'upc_code',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '包装要求',
    prop: 'pack_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '测试要求及其他',
    prop: 'test_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  }
];
//宠物窝
export const petNest = [
  {
    label: '英文描述',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '价格组成',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    rules: { required: true, message: ' ' },
    prop: 'price_composition',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品尺寸',
    prop: 'prod_size',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品克重',
    prop: 'prod_weight',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品颜色',
    prop: 'prod_color',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品数量',
    prop: 'prod_quantity',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '面料/材质',
    prop: 'fabric_material',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品描述',
    prop: 'prod_description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '包装物UPC码',
    prop: 'upc_code',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '包装要求',
    prop: 'pack_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '产品外观描述',
    prop: 'prod_face_describe',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '测试要求及其他',
    prop: 'test_ask',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  }
];
export const other = [
  {
    label: '英文描述',
    type: 'textarea',
    autosize: { minRows: 3 },
    split: 24,
    prop: 'description',
    itemType: 'input',
    rules: { required: true, message: ' ' },
    model: '',
    size: 'small',
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '中文描述',
    type: 'textarea',
    autosize: { minRows: 3 },
    rules: { required: false, message: ' ' },
    split: 24,
    prop: 'cn_description',
    itemType: 'input',
    model: '',
    size: 'small',
    subWidth: 80,
    show: false,
    disabled: false
  }
];
export const prodCatnipProperties = [
  {
    label: '猫草种类',
    prop: 'catnip_type',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 'auto',
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '英文名称',
    prop: 'mtrb_ename',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 'auto',
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '用量',
    prop: 'catnip_dosage',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 'auto',
    subItem: { type: 'input', disabled: false }
  },
  // {
  //   label: '数量',
  //   prop: 'catnip_num',
  //   itemType: 'input',
  //   input: true,
  //   sortable: false,
  //   labelWidth: 'auto',
  //   subItem: { type: 'input', disabled: false }
  // },
  {
    label: '单位',
    prop: 'mtrb_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 'auto',
    subItem: { type: 'input', disabled: false, options: {} }
  },
  {
    label: '供应商简称',
    prop: 'supp_id',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'newSelect', disabled: false, options: {} }
  }
];
/**
 * 产品类别级联菜单选项
 */
export const prodCategoryOptions = [
  {
    value: '',
    label: '毛绒玩具',
    children: [{ value: '毛绒和其他材质结合类玩具', label: '毛绒和其他材质结合类玩具' }]
  },
  { value: '胸背项圈', label: '胸背项圈' },
  { value: 'TPR', label: 'TPR' },
  { value: '狗包/零食包/汽车垫', label: '狗包/零食包/汽车垫' },
  { value: '球(Actionball)', label: '球(Actionball)' },
  {
    value: '',
    label: '橡胶',
    children: [
      { value: '橡胶发泡球', label: '橡胶发泡球' },
      { value: '橡胶和棉绳结合类产品', label: '橡胶和棉绳结合类产品' }
    ]
  },
  { value: '垃圾卷/猫砂袋', label: '垃圾卷/猫砂袋' },
  {
    value: '',
    label: '硅胶产品',
    children: [
      { value: '硅胶碗', label: '硅胶碗' },
      { value: '硅胶垫', label: '硅胶垫' },
      { value: '硅胶盖', label: '硅胶盖' }
    ]
  },
  { value: '水瓶', label: '水瓶' },
  { value: '宠物窝垫床', label: '宠物窝垫床' },
  { value: '狗口罩(牛津布)', label: '狗口罩(牛津布)' },
  { value: '镭射灯', label: '镭射灯' },
  { value: '猫树', label: '猫树' },
  { value: '纸抓板', label: '纸抓板' },
  { value: '金属类产品(狗床,食架)', label: '金属类产品(狗床,食架)' },
  {
    value: '',
    label: '手套',
    children: [
      { value: '硅胶手套', label: '硅胶手套' },
      { value: '橡胶手套', label: '橡胶手套' }
    ]
  },
  { value: '木制品', label: '木制品' },
  { value: '猫隧道/狗帐篷', label: '猫隧道/狗帐篷' },
  { value: '狗短裤/狗衣服/狗围巾', label: '狗短裤/狗衣服/狗围巾' },
  {
    value: '',
    label: '塑料制品',
    children: [
      { value: '塑料制品', label: '塑料制品' },
      { value: '塑料猫玩具', label: '塑料猫玩具' },
      { value: '塑料慢食玩具/嗅闻垫', label: '塑料慢食玩具/嗅闻垫' }
    ]
  },
  { value: '其他', label: '其他' }
];
