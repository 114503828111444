import helper from '@assets/js/helper';
import { getDateNoTime } from '@assets/js/dateUtils';

export const tableProperties = [
  { prop: 'selection', type: 'selection', labelWidth: '48px' },
  { prop: 'index', type: 'index', labelWidth: '50px', label: '序号' },
  {
    prop: 'prod_category',
    label: '产品品类',
    labelWidth: '100',
    itemType: 'select',
    options: [],
    filterable: true,
    sortable: false,
    invisible: false
  },
  {
    prop: 'image',
    label: '主图',
    labelWidth: '80px',
    itemType: 'input',
    input: false,
    sortable: false,
    subItem: {
      type: 'image',
      image: row =>
        row.imge_commodity_url
          ? helper.picUrl(row.imge_commodity_url, 'm')
          : row.imge_url
          ? helper.picUrl(row.imge_url, 'm')
          : row.imge_craft_url
          ? helper.picUrl(row.imge_craft_url, 'm')
          : null,
      thumbnail: row =>
        row.imge_commodity_url
          ? helper.picUrl(row.imge_commodity_url, 'm')
          : row.imge_url
          ? helper.picUrl(row.imge_url, 'm')
          : row.imge_craft_url
          ? helper.picUrl(row.imge_craft_url, 'm')
          : null
    }
  },
  { prop: 'prod_cust_no', label: '客户货号', labelWidth: '100', itemType: 'input' },
  { prop: 'prod_no', label: '我司货号', labelWidth: '100', itemType: 'input' },
  { prop: 'prod_poststfix', label: '货号后缀', labelWidth: '80', itemType: 'input', input: false, sortable: false },
  { prop: 'prod_partno', label: '部件名称', itemType: 'input', invisible: true },
  { prop: 'cust_abbr', label: '客户简称', itemType: 'input' },
  { prop: 'prod_usd', label: '销售价格', labelWidth: '100', itemType: 'input', align: 'right', input: false, sortable: false },
  { prop: 'prod_price', label: '采购价格', labelWidth: '100', itemType: 'input', align: 'right', input: false, sortable: false, invisible: true },
  { prop: 'supp_abbr', label: '供应商', labelWidth: '100', itemType: 'input' },
  { prop: 'prod_name', label: '中文名称', itemType: 'input', sortable: false },
  { prop: 'prod_ename', label: '英文名称', itemType: 'input', sortable: false },
  { prop: 'prod_hscode', label: '海关编码', itemType: 'input', input: false, sortable: false },
  { prop: 'prod_hsname', label: '报关中文品名', labelWidth: '110', itemType: 'input', sortable: false },
  { prop: 'prod_version_no', label: '版本号', labelWidth: '80', itemType: 'input', sortable: false, input: false },
  { prop: 'stff_name', label: '录入人', itemType: 'input', labelWidth: '80', sortable: false },
  { prop: 'create_time', label: '录入时间', itemType: 'date', formatter: val => getDateNoTime(val, true), labelWidth: '90' },
  {
    labelWidth: '90',
    prop: 'status',
    label: '单据状态',
    itemType: 'select',
    fixed: 'right',
    options: [
      { value: 0, label: '草拟' },
      { value: 1, label: '在批' },
      { value: 2, label: '生效' }
    ]
  }
];
export const expandTableProperties = [
  { prop: 'prod_no', label: '我司货号', widthAuto: true, itemType: 'input', input: false },
  { prop: 'prod_poststfix', label: '货号后缀', widthAuto: true, itemType: 'input', input: false },
  { prop: 'prod_partno', label: '部件名称', widthAuto: true, itemType: 'input', input: false },
  { prop: 'prod_cust_no', label: '客户货号', widthAuto: true, itemType: 'input', input: false },
  { prop: 'prod_price', label: '采购价', widthAuto: true, itemType: 'input', input: false },
  { prop: 'prod_unit', label: '单位', widthAuto: true, itemType: 'input', input: false },
  { prop: 'supp_abbr', label: '供应商', widthAuto: true, itemType: 'input', input: false },
  {
    prop: 'prod_mainsup',
    label: '是否主商品',
    widthAuto: true,
    itemType: 'input',
    input: false,
    formatter: val => (val === 1 ? '是' : val === 0 ? '否' : '暂无')
  }
];
export const partTableProperties = [
  {
    label: '我司货号',
    prop: 'prod_no',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { type: 'input', maxLength: 7 }
  },
  {
    label: '中文名称',
    prop: 'prod_name',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { type: 'popoverInput', required: false, maxLength: 100 }
  },
  {
    label: '货号后缀',
    prop: 'prod_poststfix',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { type: 'input', disabled: row => row.disabled, maxLength: 20 }
  },
  {
    label: '部件名称',
    prop: 'prod_partno',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { type: 'popoverInput', required: true, maxLength: 20, disabled: row => row.disabled }
  },
  {
    label: '客户货号',
    prop: 'prod_cust_no',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { type: 'input', required: true, disabled: true }
  },
  {
    label: '用量',
    prop: 'prod_use',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { type: 'input', required: true, disabled: row => row.disabled, maxLength: 8 }
  },
  { label: '单位', prop: 'prod_unit', itemType: 'input', input: false, sortable: false, widthAuto: true, subItem: { type: 'input', required: true } },
  {
    label: '采购价',
    prop: 'prod_price',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { type: 'input', maxLength: 9 }
  },
  {
    label: '供应商',
    prop: 'supp_abbr',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { type: 'input', disabled: row => row.disabled }
  },
  { label: '是否主商品', prop: 'prod_mainsup', itemType: 'input', input: false, sortable: false, widthAuto: true, subItem: { type: 'input', required: true } },
  { label: '操作', prop: 'operation', itemType: 'input', input: false, sortable: false, widthAuto: true, subItem: { type: 'input' } }
];
export const mtrlTableProperties = [
  { label: '材料编号', prop: 'mtrb_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '材料类型', prop: 'mtrb_type', itemType: 'input', input: false, sortable: false, widthAuto: true, subItem: { type: 'input' } },
  {
    label: '客户货号',
    prop: 'cust_catalog_number',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { type: 'input' }
  },
  {
    label: '中文品名',
    prop: 'mtrb_name',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { type: 'popoverInput', required: true }
  },
  {
    label: '中文规格',
    prop: 'mtrb_spec',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { required: true, type: 'popoverInput' }
  },
  {
    label: '单位耗量',
    prop: 'prod_mtrb_use',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { required: true, type: 'input', maxLength: 7 }
  },
  {
    label: '单位基数',
    prop: 'prod_mtrb_per',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { required: true, type: 'input', maxLength: 7 }
  },
  {
    label: '单耗比',
    prop: 'prod_mtrb_peruse',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { type: 'input' }
  },
  { label: '数量单位', prop: 'mtrb_unit', itemType: 'input', input: false, sortable: false, widthAuto: true, subItem: { type: 'input' } },
  { label: '采购价', prop: 'prod_mtrb_price', itemType: 'input', input: false, sortable: false, widthAuto: true, subItem: { type: 'input', maxLength: 9 } },
  { label: '供应商简称', prop: 'supp_id', itemType: 'input', input: false, sortable: false, widthAuto: true, subItem: { type: 'input' } }
];
