import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';
export const desiAPI = {
  getDesiById: topic.desiTopic + '/get_desi_by_id',
  addDesi: topic.desiTopic + '/add_desi',
  editDesi: topic.desiTopic + '/edit_desi',
  getDesis: topic.desiTopic + '/get_desis',
  get_all_desi_v1: topic.desiTopic + '/get_all_desi_v1',
  get_all_desis_v1: topic.desiTopic + '/get_all_desis_v1',
  deleteDesiByDds: topic.desiTopic + '/delete_desi_by_ids',
  copy_desi_by_id: topic.desiTopic + '/copy_desi_by_id',

  getDesignCountVoList: params => getNoCatch(`${topic.desiTopic}/getDesignCountVoList`, params),
  getDesignCountDetailVoList: params => getNoCatch(`${topic.desiTopic}/getDesignCountDetailVoList`, params),
  getProdStatistics: params => getNoCatch(`${topic.desiTopic}/getProdStatistics`, params),
  exportProdStatistics: params => getNoCatch(`${topic.desiTopic}/export_ProdStatistics`, params),
  getRequListByDeptId: params => postNoCatch(`${topic.desiTopic}/getRequListByDeptId`, params)
};
