<template>
  <div class="vg_wrapper">
    <el-row>
      <div class="vg_mb_16">
        <el-button type="primary" plain size="small" @click="clickImport">关联商品</el-button>
        <el-button type="danger" plain size="small" @click="clickDel">删除</el-button>
      </div>
      <el-col :md="24">
        <el-table
          :rowStyle="({ row, rowIndex }) => (row.index = rowIndex)"
          class="vg_cursor"
          ref="multiTable"
          :data="tableData"
          @selection-change="handleSelectionChange"
          border
        >
          <el-table-column type="selection" width="48" align="center"></el-table-column>
          <el-table-column label="序号" width="120" align="center">
            <template v-slot="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="客户货号" prop="prod_cust_no" align="center"></el-table-column>
          <el-table-column label="我司货号" prop="prod_no" align="center"></el-table-column>
          <el-table-column label="中文名称" prop="prod_name" align="center"></el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template v-slot="scope">
              <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
              <el-button type="text" size="small" @click="getProdOne(scope.row.prod_id, scope.$index)">刷新</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-dialog title="关联信息" :visible.sync="dialogVisible" width="70%">
        <ProdAssociatedList @syncData="syncData"></ProdAssociatedList>
      </el-dialog>
    </el-row>
  </div>
</template>

<script>
import pubPagination from '@/components/common/pubPagination';
import ProdAssociatedList from '@/views/MessageManagement/ProdManage/TabChild/Componet/ProdAssociatedList.vue';
import { prodAPI } from '@api/modules/prod';
import { deleteTableRow } from '@assets/js/arrayUtils';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'ProdAssociated',
  components: {
    ProdAssociatedList,
    pubPagination
  },
  data() {
    return {
      searchForm: {
        page_no: 1
      },
      tableData: [],
      totalPage: 0,
      multiSelection: [],
      currentPage: 1,
      total: 0,
      dialogVisible: false,
      tableDataMap: new Map()
    };
  },
  methods: {
    // 多选获取勾选内容
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getDataList();
    },
    handleClick(row) {
      let props = {};
      if (this.$route.path.indexOf('add') !== -1) props = this.$route.query;
      else props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      this.jump('/prod_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: props.perm_id,
            form_id: row.prod_id,
            prod_attr: row.prod_attr
          })
        )
      });
    },
    clickImport() {
      this.dialogVisible = true;
    },
    clickDel() {
      if (this.multiSelection.length === 0) return this.$message.info('至少选择一条信息');
      let { tableData, map } = deleteTableRow('product_prod_id', this.tableData, this.tableDataMap, this.multiSelection);
      this.tableData = tableData;
      this.tableDataMap = map;
    },
    syncData(val) {
      let temp = [];
      // val.forEach(item => (this.tableData.find(({ prod_id }) => item.prod_id === prod_id) ? '' : temp.push(item)));
      val.forEach(item => temp.push(item));
      this.tableData = this.tableData.concat(temp);
      this.dialogVisible = false;
    },
    getProdOne(id, index) {
      prodAPI.getProdOne({ prod_id: id }).then(({ data }) => {
        this.tableData[index].prod_no = data.prodLists.prod_no;
        this.tableData[index].prod_name = data.prodLists.prod_name;
        this.tableData[index].prod_cust_no = data.prodLists.prod_cust_no;
        this.tableData[index].supp_abbr = data.prodLists.supp_abbr;
      });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
