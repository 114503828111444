<template>
  <div>
    <el-row class="vg_mb_5">
      <el-col :md="8">
        <el-form-item label="供应商编码" prop="supp_id">
          <el-select @change="changeSuppid" filterable v-model="prodForm.supp_id" placeholder="请选择供应商编码">
            <el-option v-for="item in suppList" :key="item.supp_id" :label="item.supp_no" :value="item.supp_id"> </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="供应商简称" prop="supp_id">
          <el-select @change="changeSuppid" filterable v-model="prodForm.supp_id" placeholder="请选择供应商简称">
            <el-option v-for="item in suppList" :key="item.supp_id" :label="item.supp_abbr" :value="item.supp_id"> </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="累计订单数" prop="supp_ordr_total">
          <el-input disabled v-model="prodForm.supp_ordr_total" placeholder="请填写累计订单数" show-word-limit> </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_5">
      <el-col :md="8">
        <el-form-item label="是否主产品" prop="prod_mainsup">
          <el-radio v-model="prodForm.prod_mainsup" label="1" @change="changeMainSup">是</el-radio>
          <el-radio v-model="prodForm.prod_mainsup" label="0" @change="changeMainSup">否</el-radio>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="采购价" prop="prod_price">
          <el-input
            maxlength="9"
            @change="val => $emit('changePart', 'prod_price', val)"
            v-model="prodForm.prod_price"
            @input="prodForm.prod_price = helper.keepTNum1(prodForm.prod_price)"
            @blur="prodForm.prod_price = helper.calcPrice(prodForm.prod_price, 4, 10000)"
            placeholder="请填写采购价"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="单位用量" prop="prod_use">
          <el-input
            @change="val => $emit('changePart', 'prod_use', val)"
            v-model="prodForm.prod_use"
            maxlength="7"
            @input="val => (prodForm.prod_use = keep4Decimal(val))"
            placeholder="请填写单位用量"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_5">
      <el-col :md="8">
        <el-form-item label="物料编号">
          <el-input v-model="prodForm.mtrl_no" disabled placeholder="请填写物料编号" show-word-limit>
            <template #append>
              <el-link type="primary" @click="showDialog" class="vg_cursor_hander" :disabled="isShow">选择</el-link>
            </template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <!--物料弹窗-->
    <el-dialog title="物料" :visible.sync="dialogVisible" width="70%">
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-width="120px" size="mini">
        <el-row>
          <el-col :md="8">
            <el-form-item label="物料名称" prop="mtrl_name">
              <el-input v-model="searchForm.mtrl_name" placeholder="请输入物料名称" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="物料类型" prop="mtrl_type">
              <el-select size="small" v-model="searchForm.mtrl_type" clearable placeholder="请选择物料类型">
                <el-option v-for="item in mtrbTypeList" :key="item.id" :value="item.id" :label="item.label"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="潘通色号" prop="mtrl_color">
              <el-input v-model="searchForm.mtrl_color" placeholder="请输入潘通色号" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row class="vg_mt_16">
        <el-col :md="16">
          <el-button size="small" type="primary" icon="el-icon-search" @click="getQutbNow()" class="vg_ml_16">查询 </el-button>
          <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16">
        <el-col :md="24">
          <el-table ref="multiTable" :data="tableData" :row-key="getRowKey" @row-dblclick="dbClick" border v-loading="loading">
            <el-table-column label="物料编号" prop="mtrl_no" align="center"></el-table-column>
            <!--					  <el-table-column label="物料图片" align="center">
						  <template v-slot="scope">
							  <el-image
									  v-if="scope.row.imge_url"
									  class="vd_elimg"
									  :src="helper.picUrl(scope.row.imge_url,'m','',1)"
									  fit="fill">
							  </el-image>
							  <div v-else class="vg_9f9a9a">暂无</div>
						  </template>
					  </el-table-column>-->
            <el-table-column label="物料名称" prop="mtrl_name" align="center"></el-table-column>
            <el-table-column label="物料规格" prop="mtrl_spec" align="center"></el-table-column>
            <el-table-column label="物料类型" prop="mtrl_type" align="center" :formatter="getMtrbType" />
            <el-table-column label="物料成分" prop="mtrl_comp" align="center"></el-table-column>
            <el-table-column label="潘通色号" prop="mtrl_color" align="center"></el-table-column>
            <el-table-column label="物料单位" prop="mtrl_unit" align="center"></el-table-column>
            <el-table-column label="物料门幅" prop="mtrl_width" align="center"></el-table-column>
            <el-table-column label="物料毛高" prop="mtrl_thick" align="center"></el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16">
        <el-col :md="24">
          <pubPagination :totalPage="total" @changePageSearch="changePageSearch" ref="pubPagination" />
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { get } from '@api/request';
import { suppAPI } from '@api/modules/supp';
import { openAPI } from '@api/modules/open';
import pubPagination from '@/components/common/pubPagination';
import { keep4Decimal } from '@assets/js/regExUtil';

export default {
  name: 'prodPartsInfo',
  components: { pubPagination },
  props: {
    prodForm: {
      type: Object,
      required: true
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      suppList: [],
      searchForm: {
        page_no: 1
      },
      dialogVisible: false,
      total: 0,
      mtrbTypeList: [
        { id: 0, label: '原面料' },
        { id: 1, label: '辅料' },
        { id: 2, label: '包材' }
      ],
      selectTab: null,
      tableData: [],
      loading: true
    };
  },
  created() {
    this.initData();
  },
  methods: {
    keep4Decimal,
    initData() {
      this.getSupp();
      this.getMtrl();
    },
    //获取供应商信息
    getSupp() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    changeMainSup(val) {
      //同步的数据并且只有新增时
      if (!this.prodForm.prod_id && this.prodForm.autoInc && this.prodForm.prod_attr === '3') {
        //联动修改子表是否主商品
        this.prodForm.prod_part_list.forEach(item => {
          item.prod_mainsup = Number(val);
        });
      }
    },
    // 赋值累计订单量
    changeSuppid(val) {
      this.suppList.forEach(item => {
        if (item.supp_id === val) {
          this.prodForm.supp_ordr_total = item.supp_ordr_total;
          this.$emit('changePart', 'supp_abbr', item.supp_id);
        }
      });
      //同步的数据并且只有新增时
      if (!this.prodForm.prod_id && this.prodForm.autoInc && this.prodForm.prod_attr === '3') {
        //联动修改子表供应商
        this.prodForm.prod_part_list.forEach(item => {
          item.supp_id = val;
        });
      }
    },
    getMtrbType(row) {
      return this.mtrbTypeList.find(({ id }) => id === row.mtrl_type).label;
    },
    getQutbNow() {
      this.searchForm.page_no = 1;
      this.$refs.pubPagination.currentPage = 1;
      this.getMtrl();
    },
    getMtrl() {
      get(openAPI.getMtrls, this.searchForm).then(({ data }) => {
        if (data.code === 0) {
          this.tableData = data.data.list;
          this.total = data.data.total;
          setTimeout(() => {
            this.loading = false;
          }, 500);
        }
      });
    },
    changePageSearch(val) {
      this.searchForm.page_no = val;
      this.getMtrl();
    },
    getRowKey(row) {
      return row.mtrb_id;
    },
    dbClick(row) {
      this.prodForm.mtrl_id = row.mtrl_id;
      this.prodForm.mtrl_no = row.mtrl_no;
      this.$refs.multiTable.clearSelection();
      this.dialogVisible = false;
    },
    showDialog() {
      this.dialogVisible = true;
    },
    buttonRefresh() {
      this.$refs.searchForm.resetFields();
      this.searchForm.page_no = 1;
      this.$refs.pubPagination.currentPage = 1;
      this.getMtrl();
    }
  }
};
</script>

<style></style>
