<template>
  <div class="vg_wrapper">
    <el-row>
      <search-table
        :need-pagination="false"
        ref="multiTable"
        :data="tableData"
        :columns="tableProperties"
        v-loading="loadFlag"
        @row-dblclick="dbClickJp"
        @getTableData="getRequsList"
      >
      </search-table>
    </el-row>
  </div>
</template>

<script>
import { getNoCatch } from '@api/request';
import { requAPI } from '@api/modules/requ';
import pubPagination from '@/components/common/pubPagination';
import SearchTable from '@/components/table/SearchTableV2.vue';

export default {
  name: 'ProdRequList',
  components: {
    SearchTable,
    pubPagination
  },
  props: {
    prod_no: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tableProperties: [
        this.$store.state.index,
        { prop: 'smpl_no', itemType: 'input', label: '我司货号', widthAuto: true, sortable: false },
        { prop: 'smpl_suffix', itemType: 'input', label: '更改号', input: false, widthAuto: true, sortable: false },
        { prop: 'rqta_version', itemType: 'input', label: '货号后缀', input: false, widthAuto: true, sortable: false }
      ],
      tableData: [],
      totalPage: 0,
      loadFlag: true,
      multiSelection: [],
      currentPage: 1
    };
  },
  mounted() {
    this.$set(this.$refs.multiTable.searchForm, 'prod_no', this.prod_no);
    this.initData();
  },
  methods: {
    initData() {
      this.getRequsList();
    },
    getRequsList() {
      this.loadFlag = true;
      getNoCatch(requAPI.getRequSmpls, this.$refs.multiTable.searchForm).then(({ data }) => {
        this.tableData = data.list;
        // this.totalPage = data.total;
        setTimeout(() => {
          this.loadFlag = false;
        }, 500);
      });
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.$emit('changePordNo', row);
    }
    /*    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getRequsList();
    }*/
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
