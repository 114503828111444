<template>
  <div class="vg_wrapper">
    <el-row>
      <el-row class="vg_mb_16">
        <el-form :model="searchForm" size="mini" label-width="120px">
          <el-col :span="8">
            <el-form-item label="客户货号" prop="prod_cust_no">
              <el-input v-model="searchForm.prod_cust_no" placeholder="请填写客户货号" show-word-limit />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="我司货号" prop="prod_no">
              <el-input v-model="searchForm.prod_no" placeholder="请填写我司货号" show-word-limit />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="中文名称" prop="prod_name">
              <el-input v-model="searchForm.prod_name" placeholder="请填写中文名称" show-word-limit />
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div class="vg_mb_16">
        <el-button type="warning" size="small" @click="clickImport">关联</el-button>
        <el-button size="small" type="primary" icon="el-icon-search" class="vg_ml_16" @click="getDataList"> 查询 </el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="refresh">刷新</el-button>
      </div>
      <el-col :md="24">
        <el-table
          class="vg_cursor"
          ref="multiTable"
          :data="tableData"
          @selection-change="handleSelectionChange"
          border
          v-loading="loadFlag"
        >
          <el-table-column type="selection" align="center"></el-table-column>
          <el-table-column label="序号" width="120" align="center">
            <template v-slot="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="客户货号" prop="prod_cust_no" align="center"></el-table-column>
          <el-table-column label="我司货号" prop="prod_no" align="center"></el-table-column>
          <el-table-column label="中文名称" prop="prod_name" align="center"></el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { rqutAPI } from '@api/modules/rqut';
import pubPagination from '@/components/common/pubPagination';
import { prodAPI } from '@api/modules/prod';
export default {
  name: 'ProdAssociatedList',
  components: {
    pubPagination
  },
  data() {
    return {
      searchForm: {
        page_no: 1
      },
      tableData: [],
      totalPage: 0,
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      total: 0
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getDataList();
    },
    getDataList() {
      prodAPI.getProdList(this.searchForm).then(({ data }) => {
        this.loading = false;
        this.tableData = data.prodList;
        this.totalPage = data.total;
        setTimeout(() => {
          this.loadFlag = false;
        }, 500);
      });
    },
    // 多选获取勾选内容
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getDataList();
    },
    handleClick() {},
    clickImport() {
      this.$emit('syncData', this.multiSelection);
      this.$refs.multiTable.clearSelection();
    },
    refresh() {
      this.loadFlag = true;
      this.searchForm = { page_no: 1 };
      this.getDataList();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
