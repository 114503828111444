<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" class="addAndEdit" type="border-card" @tab-click="changeTab">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1 @isShow="isShow" ref="childTab1" />
      </el-tab-pane>
      <el-tab-pane label="审批流转" name="second" :key="'second'" :lazy="true">
        <childTab3 :isShow="isSho" @draftState="draftState" />
      </el-tab-pane>
      <el-tab-pane label="相关文档" name="third" :key="'third'">
        <childTab2 ref="childTab2" />
      </el-tab-pane>
      <el-tab-pane label="关联信息" name="four" :key="'four'">
        <el-link @click="jumpScon" class="vg_cursor">销售合同 </el-link>
        <br />
        <el-link class="vg_cursor" @click="jump('podr_list', { perm_id: 127, prod_no: JSON.parse($UrlEncode.decode($route.query.key)).prod_no })"
          >采购合同
        </el-link>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ProdEditMain from '@/views/MessageManagement/ProdManage/TabChild/ProdEditMain';
import ProdEditAbout from '@/views/MessageManagement/ProdManage/TabChild/ProdEditAbout';
import ProdEditAppr from '@/views/MessageManagement/ProdManage/TabChild/ProdEditAppr';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'ProdEdit',
  components: {
    childTab1: ProdEditMain,
    childTab2: ProdEditAbout,
    childTab3: ProdEditAppr
  },
  data() {
    return {
      activeName: 'first',
      isSho: true
    };
  },
  created() {},
  methods: {
    jumpScon() {
      this.jump('scon_list', { ...JSON.parse(UrlEncode.decode(this.$route.query.key)) });
    },

    isShow(val) {
      this.isSho = val;
    },
    draftState() {
      this.$refs.childTab1.initData();
    },
    changeTab(val) {
      if (val.index === '2') {
        this.$refs.childTab2.trigger();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.addAndEdit > ::v-deep .el-tabs__content {
  height: calc(100vh - 179px);
  overflow-y: auto;
  padding-top: 0;
}
</style>
