<template>
  <div class="vg_wrapper">
    <el-row>
      <el-col :md="24">
        <el-table class="vg_cursor" ref="multiTable" :data="tableData" @row-dblclick="dbClickJp" border v-loading="loadFlag">
          <el-table-column label="序号" width="120" align="center">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="询价单号" prop="rqut_no" align="center"></el-table-column>
          <el-table-column label="我司货号" prop="prod_no" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column label="货号后缀" prop="prod_poststfix" align="center"></el-table-column>
          <el-table-column label="客户货号" prop="prod_cust_no" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.prod_cust_no">{{ scope.row.prod_cust_no }}</span>
              <span v-else>暂无</span>
            </template>
          </el-table-column>
          <el-table-column label="客户简称" prop="cust_abbr" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.cust_abbr">{{ scope.row.cust_abbr }}</span>
              <span v-else>暂无</span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"> </pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { rqutAPI } from '@api/modules/rqut';
import pubPagination from '@/components/common/pubPagination';
export default {
  name: 'ProdRqutList',
  components: {
    pubPagination
  },
  data() {
    return {
      tableData: [],
      totalPage: 0,
      loadFlag: true,
      multiSelection: [],
      currentPage: 1
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getRqutsList();
    },
    getRqutsList() {
      get(rqutAPI.getAllRqutsV1, {
        page_no: this.currentPage
      })
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },
    // 双击row选择
    dbClickJp(row) {
      this.$emit('changePordNo', row);
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getRqutsList();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
