<template>
  <div>
    <el-row class="vg_mb_5">
      <el-col :md="8">
        <el-form-item v-if="prodForm.prod_sales === 2 || (prodForm.prod_sales === 1 && prodForm.prod_attr !== '2')" label="海关编码" prop="prod_hscode">
          <el-input v-model="prodForm.prod_hscode" disabled placeholder="请填写海关编码" show-word-limit>
            <template slot="append">
              <el-link :disabled="isShow" class="vg_cursor_hander" type="primary" @click="dialogVisible = true">选择</el-link>
            </template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item :label="prodForm.prod_sales === 1 ? '开票品名' : '报关中文品名'" prop="prod_hsname">
          <el-input
            maxlength="30"
            v-model="prodForm.prod_hsname"
            :placeholder="prodForm.prod_sales === 1 ? '请填写开票品名' : '请填写报关中文品名'"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="报关英文品名" prop="prod_ehsname">
          <el-input v-model="prodForm.prod_ehsname" maxlength="30" placeholder="请填写报关英文品名" show-word-limit></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_5">
      <el-col :md="8">
        <el-form-item label="增值税率" prop="prod_hsinrate">
          <el-input
            maxlength="7"
            v-model="prodForm.prod_hsinrate"
            @input="prodForm.prod_hsinrate = helper.keepTNum1(prodForm.prod_hsinrate)"
            @blur="prodForm.prod_hsinrate = helper.calcPrice(prodForm.prod_hsinrate, 4, 100)"
            placeholder="请填写增值税率"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="退税率" prop="prod_hsoutrate">
          <el-input
            maxlength="7"
            v-model="prodForm.prod_hsoutrate"
            @input="prodForm.prod_hsoutrate = helper.keepTNum1(prodForm.prod_hsoutrate)"
            @blur="prodForm.prod_hsoutrate = helper.calcPrice(prodForm.prod_hsoutrate, 4, 100)"
            placeholder="请填写退税率"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-dialog title="海关信息" append-to-body :visible.sync="dialogVisible" width="70%">
      <ProdCsomList @changeCustom="changeCustom"></ProdCsomList>
    </el-dialog>
  </div>
</template>

<script>
import ProdCsomList from './ProdCsomList.vue';

export default {
  name: 'prodCustom',
  props: {
    prodForm: {
      type: Object,
      required: true
    },
    isShow: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ProdCsomList
  },
  data() {
    return {
      dialogVisible: false,
      salesVisible: false
    };
  },
  methods: {
    // 海关模块传值
    changeCustom(val) {
      let temp = JSON.parse(JSON.stringify(val));
      this.prodForm.prod_hscode = temp.param1;
      this.prodForm.prod_hsname = temp.param2;
      this.prodForm.prod_ehsname = temp.param3;
      this.prodForm.prod_hsinrate = temp.param4;
      this.prodForm.prod_hsoutrate = temp.param5;
      this.dialogVisible = false;
      this.calcBody();
    },
    calcBody() {
      let regPos = /^[0-9]+.?[0-9]*$/;
      if (regPos.test(Number(this.prodForm.prod_hsinrate))) {
        if (Number(this.prodForm.prod_hsinrate) < 100) {
          this.prodForm.prod_hsinrate = this.helper.haveFour(this.prodForm.prod_hsinrate);
        } else {
          this.prodForm.prod_hsinrate = '0.0000';
        }
      } else {
        this.prodForm.prod_hsinrate = '0.0000';
      }
      if (regPos.test(Number(this.prodForm.prod_hsoutrate))) {
        if (Number(this.prodForm.prod_hsoutrate) < 100) {
          this.prodForm.prod_hsoutrate = this.helper.haveFour(this.prodForm.prod_hsoutrate);
        } else {
          this.prodForm.prod_hsoutrate = '0.0000';
        }
      } else {
        this.prodForm.prod_hsoutrate = '0.0000';
      }
    },
    salesIsShow() {
      this.salesVisible = true;
    }
  }
};
</script>

<style scoped>
/deep/ .el-form-item.is-error .el-input__inner {
  border-color: red;
}
</style>
