<template>
  <div>
    <el-row class="vg_mb_5">
      <el-col :md="8">
        <el-form-item label="产品条形码" prop="prod_bar">
          <el-input v-model="prodForm.prod_bar" maxlength="20" placeholder="请填写产品条形码" show-word-limit> </el-input>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="内包装条形码" prop="prod_inbar">
          <el-input v-model="prodForm.prod_inbar" maxlength="20" placeholder="请填写内包装条形码" show-word-limit> </el-input>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="外箱条形码" prop="prod_outbar">
          <el-input v-model="prodForm.prod_outbar" maxlength="20" placeholder="请填写外箱条形码" show-word-limit> </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_5">
      <el-col :md="8">
        <el-form-item label="外箱长度" prop="prod_boxl">
          <el-input
            @change="prodVolChange"
            v-model="prodForm.prod_boxl"
            maxlength="7"
            @input="prodForm.prod_boxl = helper.keepTNum1(prodForm.prod_boxl)"
            @blur="prodForm.prod_boxl = prodForm.prod_boxl ? new BigNumber(prodForm.prod_boxl).toFixed(2) : null"
            placeholder="请填写外箱长度"
            show-word-limit
          >
            <template slot="append">厘米</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="外箱宽度" prop="prod_boxw">
          <el-input
            @change="prodVolChange"
            v-model="prodForm.prod_boxw"
            maxlength="7"
            @input="prodForm.prod_boxw = helper.keepTNum1(prodForm.prod_boxw)"
            @blur="prodForm.prod_boxw = prodForm.prod_boxw ? new BigNumber(prodForm.prod_boxw).toFixed(2) : null"
            placeholder="请填写外箱宽度"
            show-word-limit
          >
            <template slot="append">厘米</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="外箱高度" prop="prod_boxh">
          <el-input
            @change="prodVolChange"
            v-model="prodForm.prod_boxh"
            maxlength="7"
            @input="prodForm.prod_boxh = helper.keepTNum1(prodForm.prod_boxh)"
            @blur="prodForm.prod_boxh = prodForm.prod_boxh ? new BigNumber(prodForm.prod_boxh).toFixed(2) : null"
            placeholder="请填写外箱高度"
            show-word-limit
          >
            <template slot="append">厘米</template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_5">
      <el-col :md="8">
        <el-form-item label="外箱体积" prop="prod_vol">
          <el-input v-model="prodForm.prod_vol" maxlength="10" disabled placeholder="请填写外箱体积" show-word-limit>
            <template slot="append">立方米</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="毛重" prop="prod_gweight">
          <el-input
            v-model="prodForm.prod_gweight"
            maxlength="7"
            @input="prodForm.prod_gweight = helper.keepTNum1(prodForm.prod_gweight)"
            @blur="prodForm.prod_gweight = helper.calcPriceT(prodForm.prod_gweight, 2, 10000)"
            placeholder="请填写毛重"
            show-word-limit
          >
            <template slot="append">公斤</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="净重" prop="prod_nweight">
          <el-input
            v-model="prodForm.prod_nweight"
            maxlength="7"
            @input="prodForm.prod_nweight = helper.keepTNum1(prodForm.prod_nweight)"
            @blur="prodForm.prod_nweight = helper.calcPriceT(prodForm.prod_nweight, 2, 10000)"
            placeholder="请填写净重"
            show-word-limit
          >
            <template slot="append">公斤</template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_5">
      <el-col :md="8">
        <el-form-item label="内包装数量" prop="prod_qpb">
          <el-input
            v-model="prodForm.prod_qpb"
            maxlength="10"
            @input="prodForm.prod_qpb = helper.keepTNum1(prodForm.prod_qpb)"
            placeholder="请填写内包装数量"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="外包装数量" prop="prod_qpc">
          <el-input
            v-model="prodForm.prod_qpc"
            maxlength="10"
            @input="prodForm.prod_qpc = helper.keepTNum1(prodForm.prod_qpc)"
            placeholder="请填写外包装数量"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { BigNumber } from 'bignumber.js';
import helper from '@assets/js/helper';

export default {
  name: 'prodSpec',
  computed: {
    BigNumber() {
      return BigNumber;
    },
    helper() {
      return helper;
    }
  },
  props: {
    prodForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {
    prodVolChange() {
      if (this.prodForm.prod_boxl && this.prodForm.prod_boxw && this.prodForm.prod_boxh) {
        this.prodForm.prod_vol = new BigNumber(Number(this.prodForm.prod_boxl) / 100)
          .times(Number(this.prodForm.prod_boxw) / 100)
          .times(Number(this.prodForm.prod_boxh) / 100)
          .toFixed(3);
      } else {
        this.prodForm.prod_vol = '0.000';
      }
    }
  }
};
</script>

<style></style>
