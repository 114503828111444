<template>
  <div>
    <div class="vg_mb_16">
      <el-button plain size="small" type="primary" @click="clickImport">导入材料</el-button>
      <el-button plain size="small" type="danger" @click="clickDel">删除</el-button>
    </div>
    <!--    <el-table :data="prodForm.prod_mtrb_list" border @selection-change="handleSelectionChange">
      <el-table-column align="center" type="selection" width="48"></el-table-column>
      <el-table-column align="center" label="序号" width="48">
        <template v-slot="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="材料编号" prop="mtrb_no" width="200">
        <template v-slot="scope">
          <el-form-item :prop="'prod_mtrb_list.' + scope.$index + '.mtrb_no'" label-width="0">
            <el-input v-model="prodForm.prod_mtrb_list[scope.$index].mtrb_no" disabled maxlength="30" placeholder="暂无材料编号" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="材料类型" prop="mtrb_type" width="200">
        <template v-slot="scope">
          <el-form-item :prop="'prod_mtrb_list.' + scope.$index + '.mtrb_type'" label-width="0">
            <el-select v-model="prodForm.prod_mtrb_list[scope.$index].mtrb_type" disabled placeholder="暂无材料类型" show-word-limit>
              <el-option v-for="item in mtrbTypeList" :key="item.id" :label="item.label" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="客户货号" prop="cust_catalog_number" width="200">
        <template v-slot="scope">
          <el-form-item :prop="'prod_mtrb_list.' + scope.$index + '.cust_catalog_number'" label-width="0">
            <el-input
              v-model="prodForm.prod_mtrb_list[scope.$index].cust_catalog_number"
              disabled
              maxlength="30"
              placeholder="暂无材料编号"
              show-word-limit
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="中文品名" prop="mtrb_name" width="200">
        <template slot="header">
          <span class="vg_deep_red">中文品名</span>
        </template>
        <template v-slot="scope">
          <el-form-item :prop="'prod_mtrb_list.' + scope.$index + '.mtrb_name'" :rules="[{ required: true }]" label-width="0">
            <template slot="label">
              <span style="display: none">中文品名</span>
            </template>
            <el-input v-model="prodForm.prod_mtrb_list[scope.$index].mtrb_name" disabled maxlength="30" placeholder="暂无中文品名" show-word-limit>
              <template slot="append">
                <el-popover placement="bottom" trigger="click" width="600" @show="textEnlargementShow(1, scope.$index)">
                  <textEnlargement
                    ref="textEnlargement"
                    :disabledFlag="true"
                    :textEnlargementString="textEnlargementString"
                    @textEnlargementChange="textEnlargementChange"
                  ></textEnlargement>
                  <span slot="reference"
                    ><el-link type="info"><i class="el-icon-more"></i></el-link
                  ></span>
                </el-popover>
              </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="中文规格" prop="mtrb_spec" width="200">
        <template slot="header">
          <span class="vg_deep_red">中文规格</span>
        </template>
        <template v-slot="scope">
          <el-form-item :prop="'prod_mtrb_list.' + scope.$index + '.mtrb_spec'" :rules="[{ required: true }]" label-width="0">
            <template slot="label">
              <span style="display: none">中文规格</span>
            </template>
            <el-input v-model="prodForm.prod_mtrb_list[scope.$index].mtrb_spec" disabled maxlength="100" placeholder="暂无中文规格" show-word-limit>
              <template slot="append">
                <el-popover placement="bottom" trigger="click" width="600" @show="textEnlargementShow(0, scope.$index)">
                  <textEnlargement
                    ref="textEnlargement"
                    :disabledFlag="true"
                    :textEnlargementString="textEnlargementString"
                    @textEnlargementChange="textEnlargementChange"
                  ></textEnlargement>
                  <span slot="reference"
                    ><el-link type="info"><i class="el-icon-more"></i></el-link
                  ></span>
                </el-popover>
              </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="单位耗量" prop="prod_mtrb_use" width="120">
        <template slot="header">
          <span class="vg_deep_red">单位耗量</span>
        </template>
        <template v-slot="scope">
          <el-form-item :prop="'prod_mtrb_list.' + scope.$index + '.prod_mtrb_use'" :rules="[{ required: true }]" label-width="0">
            <template slot="label">
              <span style="display: none">单位耗量</span>
            </template>
            <el-input
              v-model="prodForm.prod_mtrb_list[scope.$index].prod_mtrb_use"
              maxlength="7"
              placeholder="暂无单位耗量"
              show-word-limit
              @blur="prodForm.prod_mtrb_list[scope.$index].prod_mtrb_use = helper.retain1(prodForm.prod_mtrb_list[scope.$index].prod_mtrb_use, 2, 10000)"
              @input="prodForm.prod_mtrb_list[scope.$index].prod_mtrb_use = helper.keepTNum1(prodForm.prod_mtrb_list[scope.$index].prod_mtrb_use)"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="单位基数" prop="prod_mtrb_per" width="120">
        <template slot="header">
          <span class="vg_deep_red">单位基数</span>
        </template>
        <template v-slot="scope">
          <el-form-item :prop="'prod_mtrb_list.' + scope.$index + '.prod_mtrb_per'" :rules="[{ required: true }]" label-width="0">
            <template slot="label">
              <span style="display: none">单位基数</span>
            </template>
            <el-input
              v-model="prodForm.prod_mtrb_list[scope.$index].prod_mtrb_per"
              maxlength="7"
              placeholder="暂无单位基数"
              show-word-limit
              @blur="prodForm.prod_mtrb_list[scope.$index].prod_mtrb_per = helper.retain1(prodForm.prod_mtrb_list[scope.$index].prod_mtrb_per, 2, 10000)"
              @input="prodForm.prod_mtrb_list[scope.$index].prod_mtrb_per = helper.keepTNum1(prodForm.prod_mtrb_list[scope.$index].prod_mtrb_per)"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="单耗比" prop="prod_mtrb_peruse" show-word-limit width="120">
        <template v-slot="scope">
          <el-form-item :prop="'prod_mtrb_list.' + scope.$index + '.prod_mtrb_peruse'" label-width="0">
            <el-input :value="getProdMtrbPeruse(scope.row)" disabled maxlength="6" placeholder="暂无单耗比" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="数量单位" prop="mtrb_unit" show-word-limit width="120">
        <template v-slot="scope">
          <el-form-item :prop="'prod_mtrb_list.' + scope.$index + '.mtrb_unit'" label-width="0">
            <el-input v-model="prodForm.prod_mtrb_list[scope.$index].mtrb_unit" disabled maxlength="11" placeholder="暂无数量单位" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="采购价" prop="prod_mtrb_price" show-word-limit width="120">
        <template v-slot="scope">
          <el-form-item :prop="'prod_mtrb_list.' + scope.$index + '.prod_mtrb_price'" label-width="0">
            <el-input
              v-model="prodForm.prod_mtrb_list[scope.$index].prod_mtrb_price"
              maxlength="9"
              placeholder="暂无采购价"
              show-word-limit
              @blur="prodForm.prod_mtrb_list[scope.$index].prod_mtrb_price = helper.calcPrice(prodForm.prod_mtrb_list[scope.$index].prod_mtrb_price, 4, 10000)"
              @input="prodForm.prod_mtrb_list[scope.$index].prod_mtrb_price = helper.keepTNum1(prodForm.prod_mtrb_list[scope.$index].prod_mtrb_price)"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="供应商简称" prop="supp_id" show-word-limit width="200">
        <template v-slot="scope">
          <el-form-item :prop="'prod_mtrb_list.' + scope.$index + '.supp_id'" label-width="0">
            <el-select v-model="prodForm.prod_mtrb_list[scope.$index].supp_id" clearable disabled placeholder="暂无供应商简称">
              <el-option v-for="item in suppList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>-->
    <DynamicUTable
      ref="mtrlDynamicUTable"
      :columns="mtrlTableProperties"
      :form="prodForm"
      :is-show="isShow"
      :need-pagination="false"
      :table-data="prodForm.prod_mtrb_list"
      :needToVerifyCharacterLength="true"
      @selection-change="handleSelectionChange"
    >
      <template v-slot:mtrb_no="scope">
        <el-form-item :prop="'prod_mtrb_list.' + scope.$index + '.mtrb_no'" label-width="0">
          <el-input v-model="scope.row.mtrb_no" disabled maxlength="30" placeholder="暂无材料编号" show-word-limit></el-input>
        </el-form-item>
      </template>
      <template v-slot:mtrb_type="scope">
        <el-form-item :prop="'prod_mtrb_list.' + scope.$index + '.mtrb_type'" label-width="0">
          <el-select v-model="scope.row.mtrb_type" disabled placeholder="暂无材料类型" show-word-limit>
            <el-option v-for="item in mtrbTypeList" :key="item.id" :label="item.label" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <template v-slot:cust_catalog_number="scope">
        <el-form-item :prop="'prod_mtrb_list.' + scope.$index + '.cust_catalog_number'" label-width="0">
          <el-input v-model="scope.row.cust_catalog_number" disabled maxlength="30" placeholder="暂无材料编号" show-word-limit></el-input>
        </el-form-item>
      </template>
      <template v-slot:mtrb_name="scope">
        <el-form-item :prop="'prod_mtrb_list.' + scope.$index + '.mtrb_name'" :rules="[{ required: true }]" label-width="0">
          <template slot="label"><span style="display: none">中文品名</span></template>
          <el-input v-model="scope.row.mtrb_name" disabled maxlength="30" placeholder="暂无中文品名" show-word-limit>
            <template slot="append">
              <el-popover placement="bottom" trigger="click" width="600" @show="textEnlargementShow(1, scope.$index)">
                <textEnlargement
                  ref="textEnlargement"
                  :disabledFlag="true"
                  :textEnlargementString="textEnlargementString"
                  @textEnlargementChange="textEnlargementChange"
                ></textEnlargement>
                <span slot="reference"
                  ><el-link type="info"><i class="el-icon-more"></i></el-link
                ></span>
              </el-popover>
            </template>
          </el-input>
        </el-form-item>
      </template>
      <template v-slot:mtrb_spec="scope">
        <el-form-item :prop="'prod_mtrb_list.' + scope.$index + '.mtrb_spec'" :rules="[{ required: true }]" label-width="0">
          <template slot="label"><span style="display: none">中文规格</span></template>
          <el-input v-model="scope.row.mtrb_spec" disabled maxlength="100" placeholder="暂无中文规格" show-word-limit>
            <template slot="append">
              <el-popover placement="bottom" trigger="click" width="600" @show="textEnlargementShow(0, scope.$index)">
                <textEnlargement
                  ref="textEnlargement"
                  :disabledFlag="true"
                  :textEnlargementString="textEnlargementString"
                  @textEnlargementChange="textEnlargementChange"
                ></textEnlargement>
                <span slot="reference"
                  ><el-link type="info"><i class="el-icon-more"></i></el-link
                ></span>
              </el-popover>
            </template>
          </el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_mtrb_use="scope">
        <el-form-item :prop="'prod_mtrb_list.' + scope.$index + '.prod_mtrb_use'" :rules="[{ required: true }]" label-width="0">
          <template slot="label"><span style="display: none">单位耗量</span></template>
          <el-input
            v-model="scope.row.prod_mtrb_use"
            maxlength="7"
            placeholder="暂无单位耗量"
            show-word-limit
            @blur="scope.row.prod_mtrb_use = helper.retain1(scope.row.prod_mtrb_use, 2, 10000)"
            @input="scope.row.prod_mtrb_use = helper.keepTNum1(scope.row.prod_mtrb_use)"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_mtrb_per="scope">
        <el-form-item :prop="'prod_mtrb_list.' + scope.$index + '.prod_mtrb_per'" :rules="[{ required: true }]" label-width="0">
          <template slot="label"><span style="display: none">单位基数</span></template>
          <el-input
            v-model="scope.row.prod_mtrb_per"
            maxlength="7"
            placeholder="暂无单位基数"
            show-word-limit
            @blur="scope.row.prod_mtrb_per = helper.retain1(scope.row.prod_mtrb_per, 2, 10000)"
            @input="scope.row.prod_mtrb_per = helper.keepTNum1(scope.row.prod_mtrb_per)"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_mtrb_peruse="scope">
        <el-form-item :prop="'prod_mtrb_list.' + scope.$index + '.prod_mtrb_peruse'" label-width="0">
          <el-input :value="getProdMtrbPeruse(scope.row)" disabled maxlength="6" placeholder="暂无单耗比" show-word-limit></el-input>
        </el-form-item>
      </template>
      <template v-slot:mtrb_unit="scope">
        <el-form-item :prop="'prod_mtrb_list.' + scope.$index + '.mtrb_unit'" label-width="0">
          <el-input v-model="scope.row.mtrb_unit" disabled maxlength="11" placeholder="暂无数量单位" show-word-limit></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_mtrb_price="scope">
        <el-form-item :prop="'prod_mtrb_list.' + scope.$index + '.prod_mtrb_price'" label-width="0">
          <el-input
            v-model="scope.row.prod_mtrb_price"
            maxlength="9"
            placeholder="暂无采购价"
            show-word-limit
            @blur="scope.row.prod_mtrb_price = helper.calcPrice(scope.row.prod_mtrb_price, 4, 10000)"
            @input="scope.row.prod_mtrb_price = helper.keepTNum1(scope.row.prod_mtrb_price)"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:supp_id="scope">
        <el-form-item :prop="'prod_mtrb_list.' + scope.$index + '.supp_id'" label-width="0">
          <el-select v-model="scope.row.supp_id" clearable disabled placeholder="暂无供应商简称">
            <el-option v-for="item in suppList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </template>
    </DynamicUTable>
    <el-dialog :visible.sync="dialogVisible" title="材料" top="0" width="70%" @opened="getMtrb">
      <el-button class="vg_mb_8" size="small" type="danger" @click="clickCancel">取消选择</el-button>
      <el-button class="vg_mb_8 vg_mr_8" size="small" type="primary" @click="confirmIn()">确认选择</el-button>
      <el-button class="vg_mb_8" icon="el-icon-refresh-right" size="small" type="info" @click="refresh">刷新</el-button>
      <search-table
        ref="multiTable"
        v-loading="loading"
        :columns="tableProperties"
        :data="tableData"
        :need-fixed-height="true"
        :tableRowKey="'mtrb_id'"
        :total-page="total"
        @getTableData="getMtrb"
        @selection-change="handleSelectionChangeImport"
      />
    </el-dialog>
  </div>
</template>

<script>
import { get, getNoCatch } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import { mtrbAPI } from '@api/modules/mtrb';
import { suppAPI } from '@/api/modules/supp';
import pubPagination from '@/components/common/pubPagination';
import textEnlargement from '@/views/component/textEnlargement.vue';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { getSupp } from '@api/public';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { mtrlTableProperties } from '@/views/MessageManagement/ProdManage/prod';

export default {
  name: 'ProdMasc',
  components: {
    DynamicUTable,
    SearchTable,
    pubPagination,
    textEnlargement
  },
  props: {
    prodForm: {
      type: Object,
      required: true
    },
    isShow: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      mtrlTableProperties: mtrlTableProperties,
      tableProperties: [
        this.$store.state.selection,
        this.$store.state.index,
        { label: '材料编号', prop: 'mtrb_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
        {
          label: '材料类型',
          prop: 'mtrb_type',
          itemType: 'select',
          options: [
            { value: 1, label: '辅料' },
            { value: 2, label: '包材' }
          ],
          formatter: val => (val === 1 ? '辅料' : '包材'),
          input: true,
          sortable: false,
          widthAuto: true
        },
        {
          label: '客户货号',
          prop: 'cust_catalog_number',
          itemType: 'input',
          input: true,
          sortable: false,
          widthAuto: true
        },
        {
          label: '中文品名',
          prop: 'mtrb_name',
          itemType: 'input',
          input: true,
          sortable: false,
          widthAuto: true
        },
        {
          label: '中文规格',
          prop: 'mtrb_spec',
          itemType: 'input',
          input: false,
          sortable: false,
          widthAuto: true,
          overflowTooltip: true
        },
        {
          label: '供应商简称',
          prop: 'supp_id',
          itemType: 'select',
          options: [],
          needOtherColumn: true,
          filterable: true,
          formatter: row => row.supp_abbr,
          input: true,
          sortable: false,
          widthAuto: true
        }
      ],
      total: 0,
      smplFlag: 0,
      selectionsList: [],
      tableData: [],
      selectTab: [],
      suppList: [],
      dialogVisible: false,
      loading: false,
      smplType: [
        { id: 1, label: '新款' },
        { id: 2, label: '老款' },
        { id: 3, label: '修改款' }
      ],
      mtrbTypeList: [
        { id: 1, label: '辅料' },
        { id: 2, label: '包材' }
      ],
      mtrbOfferList: [
        { value: 1, label: '自供' },
        { value: 2, label: '客供' }
      ],
      selectFlag: false,
      beloType: [],
      textEnlargementNum: 0,
      textEnlargementFlag: 0,
      textEnlargementString: {
        str: ''
      }
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      this.getContry();
      this.suppList = await getSupp();
      this.tableProperties[7].options = this.suppList;
    },
    getProdMtrbPeruse(row) {
      row.prod_mtrb_peruse = this.helper.retain1(row.prod_mtrb_use / row.prod_mtrb_per, 2, 10000);
      return row.prod_mtrb_peruse;
    },
    getMtrb() {
      getNoCatch(mtrbAPI.getAllMtrbsV1, this.$refs.multiTable.searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.total = data.total;
      });
    },
    getSupp() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 获取打样部门
    getContry() {
      get(optnAPI.getOptnByPermId, { perm_id: 10009 })
        .then(res => {
          if (res.data.code === 0) {
            let beloType = res.data.data.form.optn_cntt_list;
            for (let item of beloType) {
              item.param2 = Number(item.param2);
            }
            this.beloType = beloType;
          }
        })
        .catch(() => {});
    },
    //bom清单选择框
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
    },
    clickImport() {
      this.dialogVisible = true;
    },
    //取消选择
    clickCancel() {
      this.$refs.multiTable.clearSelection();
    },
    confirmIn() {
      let arr = [];
      this.selectTab.forEach(item => {
        let flag = this.prodForm.prod_mtrb_list.some(item2 => {
          return item.mtrb_id === item2.mtrb_id;
        });
        if (!flag) {
          arr.push(item);
        }
      });

      arr.forEach(item => {
        let num = 1;
        if (item.mtrb_name === '猫草') {
          num = 2;
          item.prod_mtrb_price = '0.0000';
        } else {
          item.prod_mtrb_price = this.helper.haveFour(item.mtrb_price);
        }
        item.prod_mtrb_peruse = 1;
        this.prodForm.prod_mtrb_list.push({ ...item, prod_mtrb_use: '1.00', prod_mtrb_per: '1.00', prod_mtrb_offer: num });
      });
      this.$refs.multiTable.clearSelection();
      this.dialogVisible = false;
    },
    getRowKey(row) {
      return row.mtrb_id;
    },
    handleSelectionChangeImport(select) {
      this.selectTab = select;
    },
    //弹框 分页查询
    changePageSearch(val) {
      this.searchForm.page_no = val;
      this.getMtrb();
    },
    buttonRefresh() {
      this.$refs.searchForm.resetFields();
      this.searchForm.page_no = 1;
      this.$refs.pubPagination.currentPage = 1;
      this.getMtrb();
    },
    getQutbNow() {
      this.searchForm.page_no = 1;
      this.$refs.pubPagination.currentPage = 1;
      this.getMtrb();
    },
    getMtrbType(row) {
      if (row.mtrb_type === 1) {
        return '辅料';
      } else {
        return '包材';
      }
    },
    // 框显示效果
    textEnlargementShow(val, val1) {
      this.textEnlargementFlag = val;
      this.textEnlargementNum = val1;
      if (val === 0) {
        this.textEnlargementString.str = this.prodForm.prod_mtrb_list[val1].mtrb_spec;
      } else if (val === 1) {
        this.textEnlargementString.str = this.prodForm.prod_mtrb_list[val1].mtrb_name;
      }
    },
    // 更改值
    textEnlargementChange(val) {
      if (this.textEnlargementFlag === 0) {
        this.prodForm.prod_mtrb_list[this.textEnlargementNum].mtrb_spec = val;
      } else if (this.textEnlargementFlag === 1) {
        this.prodForm.prod_mtrb_list[this.textEnlargementNum].mtrb_name = val;
      }
    },
    clickDel() {
      //selectionsList
      if (this.selectionsList.length === 0) {
        return this.$message.info('至少选择一条信息');
      }
      this.$confirm('确定移除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.selectionsList.forEach((item, index) => {
            this.prodForm.prod_mtrb_list.forEach((item2, index2) => {
              if (item.mtrb_id === item2.mtrb_id) {
                //  if(this.rqutForm.rqut_bidd_list[index2].destroy_flag!==0){
                //      let flag =  this.delList.some(item =>{
                //         return item.supp_id === this.rqutForm.rqut_bidd_list[index2].supp_id
                //     })
                //     if(!flag){
                //      this.rqutForm.rqut_bidd_list[index2].destroy_flag = 1
                //      this.delList.push(this.rqutForm.rqut_bidd_list[index2])
                //     }
                // }
                this.prodForm.prod_mtrb_list.splice(index2, 1);
              }
            });
            // }
          });
        })
        .catch(() => {});
    },
    refresh() {
      this.$refs.multiTable.resetFields();
      this.getMtrb();
    }
  }
};
</script>

<style lang="scss" scoped>
.vd_total {
  font-size: 24px;
  text-align: right;
  width: 100%;
  padding-right: 30px;
  box-sizing: border-box;
}

.vd_color {
  color: #c70e0e;
}

::v-deep .el-form-item.is-required .el-form-item__label:before {
  content: '';
  display: none;
}
</style>
